/* lunch menu stylesheet */

.menu-month-name {
    margin: 0 20px 10px;
    font-weight: bold;
}

.menu-settings {
	clear: both;
}

#menu-dialog {
    .menu-textarea {
        height: 250px;

        p {
          margin: 0 0 (@line-height-computed / 5);
        }
    }

    .menu-grades {
        margin: 15px 0;
    }
} /* end: #menu-dialog */

@media (max-width: (@screen-sm-min - 1)) {
    .menu-print-button {
        padding-top: 20px;
    }
}

.in-cart,
.not-in-cart,
.already-purchased {
    .btn;
    .btn-xs;
}

.in-cart {
    .btn.disabled;
    .btn-default;
}

.not-in-cart {
    .btn-success;
}

.already-purchased {
    .btn.disabled;
    .btn-link;
    .text-muted;
}

table.small-menu-calendar {
    border-collapse: collapse;
    margin: 5px 0;

    th {
        .text-center;
    }

    td {
        border: solid 1px #000 !important;
        width: (100 / 7);
        .text-center;
        padding: 4px;

        &.empty-day {
            background-color: @gray-lighter;
        }

        &.menu-addable {
            cursor: pointer;
        }

        &.menu-weekend {
            background-color: @gray-light;
            color: @gray-lighter;
        }

        &.menu-not-addable {
            cursor: @cursor-disabled;
            background-color: @gray-light;
        }

        &.menu-added {
            background-color: @alert-info-bg;
            color: @alert-info-text;
        }

        &.has-lunch {
            background-color: @alert-success-bg;
            color: @alert-success-text;
        }
    } /* end: td */
} /* end: table.small-menu-calendar */

/* create a bold circle around the dates that they've purchased */
@media print {
    span.circle-print {
        display: inline-block;
        height: 25px;
        width: 25px;
        line-height: 22px;

        border: solid 2px black;
        -moz-border-radius: 50%; /* or 50% */
        border-radius: 50%; /* or 50% */

        background-color: white;
        color: black;
        text-align: center;
        font-weight: bold;
    }
}

table.menu-calendar {
    border-collapse: collapse;
    width: 99%;
    margin: 0 5px;

	th {
		.text-center;
	}

	td {
		border: solid 1px #000 !important;
		width: 20%;
        min-width: 150px;
		height: 125px;
		padding: 10px 5px 5px 5px;
		vertical-align: top;
		font-size: @font-size-small;
        position: relative;

		p {
			line-height: (@line-height-computed / 1.5);
			margin: 0 0 (@line-height-computed / 5);
		}

        &.empty-day {
            background-color: @gray-lighter;
        }

        .menu-day-number {
            font-weight: bold;
            position: relative;
            top: 0;
            left: 0;
            width: 30px;
        }

        .menu-body {
            padding-top: 10px;
            font-size: @font-size-small;
            min-height: 150px;
        }

        .menu-button {
            float: right;
            margin-right: 5px;
            margin-top: 5px;
        }

        .add-students {
            margin-top: 10px;
            padding-top: 10px;
            border-top: solid 1px @gray-lighter;

            .in-cart,
            .not-in-cart {
                .btn-block;
            }

            p {
                font-weight: bold;
                .text-center;
            }
        } /* end: .add-students */

        .print-purchased-students {
            margin-top: 10px;
            border-top: solid 1px #eee;
            font-size: 10px;
            .text-center;
        }

        .cannot-purchase {
            .text-center;
            //font-weight: bold;
            font-style: italic;
            margin-top: 10px;
            font-size: 90%;
            color: @gray-dark;
        }
	} /* end: td */
} /* end: table.menu-calendar */
