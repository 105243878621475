/* shopping cart stylesheet */

#cart-large {
    width: 250px;

    &.affix {
        top: 10px;
    }

    .panel-body {
        min-height: 100px;

        #cart-contents {
            max-height: 375px;
            overflow: auto;
        }
    }

    #cart-contents {
        font-size: ceil((@font-size-base * 0.9));

        a {
            margin-left: 3px;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 1px 0;

            &.totals {
                .text-center;
                padding: 5px 0;
            }
        }
    }
} /* end: #cart-large */

#cart-small {
    width: 100%;
}

@media (max-width: (@screen-md-min - 1)) {
    body {
        /* account for the fixed cart footer navbar */
        padding-bottom: @navbar-height + 10;
    }
}
