@import "bootstrap/bootstrap";

@brand-primary: #262a35;
@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;



body, label, .checkbox label {
	font-weight: 300;
}

h3 {
    font-weight: bold;
    border-bottom: solid 1px @gray-lighter;
    padding-bottom: 5px;
}

h4 {
    margin-top: 50px;
    border-bottom: solid 1px @gray-light;
    padding-bottom: 5px;

    &.no-margin {
        margin-top: 0;
    }
}

.bold {
    font-weight: bold;
}

.no-close .ui-dialog-titlebar-close {
    display: none;
}

.btn-icon-only {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    padding: 0 1px;
}

.right-small {
    text-align: right;
    font-size: @font-size-small;
}

tr.payment-deleted td {
	text-decoration: line-through;
	font-style: italic;
	.text-muted;
}

footer {
    margin-top: 15px;
    padding: 5px;
    border-top: solid 1px @gray;

    p {
        color: @gray;
    }
}

@import "menu";
@import "cart";
